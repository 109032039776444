import React from 'react';
import wincanton from "../images/win-logo.png";
import '../styles/style.css';

const Footer = () => {
    return (
        <div className="footer" style={{ 
          backgroundColor: '#f0f0f0',
          borderTop: '1px solid #ddd',
          marginTop: "91px"
        }}> 
            <footer style={{ 
                fontFamily: "Ubuntu", 
                fontSize: '18px', 

                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                textAlign: "right",
                marginRight: "10px"
            }}>
                <div>
                <img src={wincanton} alt="wincanton-logo" style={{ width: '200px' }} /> 
                </div>
                <div>
                    <p style={{ textAlign: "right", fontSize: '16px', fontWeight: 'normal', marginRight: "15px" }}>&copy; 2023-{new Date().getFullYear()} Wincanton PLC - Finance Development Team</p>

                </div>
            </footer> 
        </div>
    );
}

export default Footer;
