import React from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
// Material-UI imports
import Grid from "@mui/material/Grid";


// Sample app imports
import { PageLayout } from "./ui-components/PageLayout";
import { Home } from "./pages/Home";



function App() {

  return (

    <PageLayout className="page-layout">
      <Grid container justifyContent="center" className="grid-container">
        <Pages />
      </Grid>
    </PageLayout>

  );
}

function Pages() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
    </Router>
  );
}

export default App;