import React from 'react';
import {
  Paper,
  Typography,

} from '@mui/material';
import horse from "../images/horse.png";
import monocle from "../images/face_with_monocle.png"
import Footer from "../ui-components/Footer";
import '../styles/style.css';

function SupportMessage() {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Typography variant="body1" style={{ fontSize: "22px", textAlign: "center", fontWeight: 'bold', fontFamily: "ubuntu" }}>
      <p>You've galloped into a nebulous corner of the cosmos,</p>
      <p>that's reserved for the star-studded Nebula Development Team.🌟</p>

      <p>If you're not part of this interstellar crew, fear not!</p>
      <p>Just back up your spaceship slowly, and nobody will be sucked into a black hole.🕳️</p>

      <p>For the cosmic adventurers who belong here, proceed with the secret handshake. 👽</p>

      <p>Everyone else, <a href="https://wincanton-ssc.co.uk">click here</a>  to teleport to safety!</p>

      <p>This message will self-destruct in 5... 4... 3... just kidding</p>
      </Typography>
    </div>
  );
}

function UnderlinedText({ children }) {
  const styles = {
    textUnderline: {
      position: 'relative',
    },
    textUnderlineAfter: {
      content: '',
      position: 'absolute',
      left: 0,
      bottom: -6,
      height: '6px', // Adjust thickness
      width: '100%',
      backgroundColor: '#DE772E',
    },
  };

  return <span style={styles.textUnderline}>{children}<span style={styles.textUnderlineAfter}></span></span>;
}

export function Home() {

  return (
    <>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", maxWidth: "1200px" }}>
        <div style={{
          textAlign: "left",
          fontSize: "65px",
          fontFamily: "Ubuntu",
          fontWeight: "bold",
          marginTop: "100px",
          marginBottom: "10px",
          width: "100%",
          maxWidth: "1200px"
        }}>

          <Typography variant="h1" style={{ lineHeight: "2", fontSize: "85px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

            <UnderlinedText> Whoa, Nelly! </UnderlinedText>
            <img src={horse} alt="monocle" style={{ marginLeft: "10px", width: "100px", height: "100px" }} />
            



          </Typography>
          <Typography variant="h1" style={{ fontSize: "50px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

          Hold Your Horses... and Your Monocles!
          <img src={monocle} alt="monocle" style={{ marginLeft: "10px", width: "50px", height: "50px" }} />


          </Typography>


        </div>
        <Paper elevation={3} style={{ border: '6px solid #DE772E', borderRadius: '10px', padding: '3rem', textAlign: 'center', marginTop: '10px', width: "100%", maxWidth: "1100px" }}>
          <SupportMessage />
          <div>

          </div>


        </Paper>

      </div>
      <div style={{

        width: '100%',
      }}>
        <Footer />
      </div>


    </>
  );
}
