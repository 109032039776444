import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import GatewayLogo from "../images/gateway-logo.png";

const NavBar = () => {
  return (
    <div sx={{ flexGrow: 1 }}>
      <AppBar sx={{ width: "100%", bgcolor: '#041E42'}}>
        <Toolbar>
          <Typography sx={{ flexGrow: 1 }}>

              <img src={GatewayLogo} alt="BPS Gateway" style={{ width: 'auto', height: '40px' }} />

          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
